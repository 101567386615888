@tailwind components;
@tailwind utilities;
*{
  margin: 0px;
  padding: 0px;
  font-family: DanaVF;
}

body {
  background-color: #181D34 !important;
  color: #FFFFFF!important;
  direction: rtl;
  width: 100vw;
  overflow: hidden;
  overflow-y: scroll;
}

a{
  width: 100%;
  display: contents;
  justify-content: center;
}