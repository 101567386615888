.menu {
  /* background: linear-gradient(
      90deg,
      rgba(34, 34, 34, 0) 12.85%,
      rgba(25, 29, 50, 0.83125) 66.92%,
      rgba(24, 29, 52, 0.924446) 91.32%,
      rgba(24, 29, 52, 0.95) 104.72%
    ),
    url("/public/assets/images/baner/menu-banner.jpg"); */
  width: 100%;
  height: 530px;
  padding: 16px 118px;
}

.right-menu {
  @apply flex items-center gap-12
}



.menu-items-wrapper .route {
  height: 24px;
  font-family: "DanaVF";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #ffffff;
  flex: none;
  order: 5;
  flex-grow: 0;
  margin: 0px 10px;
  cursor: pointer;
  transition: all 0.2s;
  padding-bottom: 36px;
  display: inline-block;
}

.menu-items-wrapper .route:hover {
  color: #f9302f;
  /* font-weight: bold; */
}

.menu-items-wrapper .active-route {
  color: #f9302f;
  font-weight: bold;
}
.burger-menu .active-route {
  color: #f9302f;
  font-weight: bold;
}

.sub-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 32px 40px 40px;
  /* gap: 8px; */
  position: absolute;
  width: max-content;
  height: fit-content;
  left: -76px;
  /* top: 36px; */
  background: #181d34;
  box-shadow: 0px 0px 24px rgb(0 0 0 / 16%);
  border-radius: 8px;
  list-style-type: disc;
  transform: scaleY(0);
  transition: all 0.2s;
  padding-bottom: 0px;
  box-sizing: border-box;
}

.show-sub-menu {
  transform: scaleY(1);
}

.sub-menu .arrow-down {
  margin: 0px auto;
  display: flex;
  margin-top: -10px;
  width: 14px;
}

.sub-menu ul,
.sub-menu li {
  width: 100%;
  transition: all 0.2s;
  padding: 0px;
  box-sizing: border-box;
}

.sub-menu li {
  font-size: 14px;
  line-height: 24px;
  padding: 6px 0px;
  margin: 6px auto;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
  box-sizing: border-box;
}

.sub-menu li:hover {
  color: #f9302f;
  /* margin-right: 4px; */
  /* margin-left: 0px; */
  transition: all 0.2s;
}

.language-wrapper {
  margin: 0px 8px;
}

.language {
  cursor: pointer;
}

.left-side {
  display: flex;
  align-self: center;
}

.left-side img {
  margin: 0px 27px;
}

.language-label {
  margin-left: 27px;
}
.language-label-en {
  margin-right: 27px;
}

.language-active {
  color: #f9302f;
}

.hamberger {
  display: none !important;
}

@media screen and (max-width: 1100px) {
  .menu {
    padding: 16px;
  }

  .desktop {
    display: none !important;
  }

  .hamberger {
    display: flex !important;
  }
}
.bm-menu{
  background-color: rgba(24, 29, 52, 0.9) !important;
  transform: translateY(-40px);
}