.centered {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  right: 10%;
  left: 40%;
  transform: translate(4%, -50%);
}
.nav-centered {
  position: absolute;
  top: 55%;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* left: 40%; */
  transform: translate(4%, -50%);
}

.lightboxContainer {
  position: relative;
  display: inline-block;
}

.lightboxContainer:after {
  content: url("https://www.wonderplugin.com/download/playbutton.png");
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -32px;
  margin-top: -32px;
}

.first-txt {
  direction: rtl;
  position: absolute;
  top: 17px;
  right: 10px;
}

.second-txt {
  direction: rtl;
  position: absolute;
  bottom: 20px;
  right: 10px;
}

.box-text-color {
  color: red !important;
}

.down-arrow {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid red;
  margin: auto;
}

.customErrorAlert {
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 8px;
  border: none;
  margin: 0px auto;
}

.my-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
  gap: 8px;
  height: 160px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  flex: none;
  order: 2;
  flex-grow: 0;
  border: 2px solid rgba(255, 255, 255, 0);
  cursor: pointer;
}
