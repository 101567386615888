.footer {
  background: rgba(24, 29, 52, 0.8);
  padding: 10px 28px;
  border-top: 2px solid #39416e;
  padding-top: 80px;
  margin-top: 72px;
  @apply flex flex-col md:flex-row;
}
.footer > div {
  @apply flex flex-col items-center md:items-start;
}

.about-tower-title {
  /* درباره تاورکمپانی */

  width: 100%;
  height: 29px;

  /* Title 2 */

  font-family: "DanaVF";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */


  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.about-tower-desc {
  width: 100%;
  /* height: 64px; */


  font-family: "DanaVF";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */


  color: #d9d9d9;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.menu-items {
  width: 100%;
  font-family: "DanaVF";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  transition: all 0.2s;
}

.menu-items:hover {
  color: rgba(249, 52, 52, 0.5);
}

.menu-items-active {
  color: #f93736;
}

.bold {
  font-weight: bold;
}

